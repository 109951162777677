import {HiArrowNarrowRight} from 'react-icons/hi'
import { Link } from 'react-scroll'

function Home() {
  return (
    <div name='home' className='w-full h-screen bg-[#0a192f]'>

    {/* Container */}
        <div className='max-w-[1000px] mx-auto px-8 flex flex-col justify-center h-full'>
            <p className='text-pink-600 px-8'>Hi, my name is</p>
            <h1 className='text-4xl sm:text-7xl font-bold text-[#ccd6f6] px-8'>Milos Adnadjevic</h1>
            <h2 className='text-4xl sm:text-7xs font bold text-[#8892b0] px-8'>I'm a Software Engineer</h2>
            <p className='text-[#8892b0] py-4 max-2-[700px] px-8'>
                I am a software engineer specializing in building exeptional digital expreriences.
                Currently, I'm focused on building accessible, human-centered products for a variety of clients.
            </p>
            <div className='px-8'>
            <Link to="work" smooth={true} duration={500}>
                     <button 
                className='text-white group border-2 px-6 py-3 my-2 flex items-center
                hover:bg-pink-600
                hover:border-pink-600'>View Work
                    <span className='group-hover:rotate-90 duration-300'>
                    <HiArrowNarrowRight className='ml-3'/>
                    </span>
                </button>    
                    </Link>
               
            </div>
        </div>
    </div>
  )
}

export default Home