import React from 'react'

const Gap = () => {
  return (
    <div name='gap' className='w-full h-24 bg-[#0a192f] text-gray-300'>
    
</div>
  )
}

export default Gap