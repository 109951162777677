import React from 'react'


function Resume() {
  return (
    <div name='resume' className='w-full h-screen bg-[#0a192f] text-gray-300'>
    <div className='flex flex-col justify-center items-center w-full h-full'>
        <div className='max-w-[1000px] w-full grid grid-cols-2 gap-8'>
            <div className='sm:text-right pb-8 pl-4'>
                <p className='text-4xl font-bold inline border-b-4 border-pink-600'>
                    Resume
                </p>
            </div>
            <div></div>
            </div>
            <div className='max-2-[1000px] w-full grid sm:grid-cols-2 gap-8 px-4'>
                <div className='sm:text-right text-4xl font-bold'>
                    <p>Milos Adnadjevic</p>
                    <hr />
                </div>
                <div>
                    <p>
                    As a software engineer I set myself apart in the industry by leveraging
                     a unique combination of skills that encompasses real estate and mechanical 
                     engineering. Employers benefit from my exceptional attention to detail, deep
                      understanding of engineering process, effective communication skills
                       which enables me to tackle complex challenges and deliver innovative 
                       solutions. Employers can rely on me to provide a fresh perspective, 
                       drive operational efficiency and deliver tangible value. My commitment
                        to continuous learning and staying up to date with the latest industry 
                        trends allows me to adapt and evolve in the ever-changing tech landscape.
                    </p><br />
                    <p className='font-bold'>Skills:</p>
                    <p> JavaScript, Node.js, JQuery, Express, MongoDB, React, Heroku, SQL, Python,
                         Django, PostgreSQL, Mongoose, GitHub, Git, HTML, CSS, Postman, Tailwind,Bootstrap</p>
                </div>
            </div>
        </div>
</div>
  )
}

export default Resume