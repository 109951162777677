import About from "./components/About";
import Navbar from "./components/Navbar";
import Home from "./components/Home";
import Skills from "./components/Skills";
import Work from "./components/Work";
import Contact from "./components/Contact";
import Resume from "./components/Resume";
import Gap from "./components/Gap";



function App() {
  return (
    <div>
      <Navbar />
      <Home />
      <Skills />
      <Gap />
      <About />
      <Work />
      <Contact />
      <Resume />
      
    </div>
  );
}

export default App;
