import Logo from '../assets/A.png'
import {FaBars, FaTimes, FaGithub, FaLinkedin, } from 'react-icons/fa'
import { HiOutlineMail } from 'react-icons/hi'
import {BsFillPersonLinesFill} from 'react-icons/bs'
import { useState } from 'react'
import { Link } from 'react-scroll'

const Navbar = () => {
    const [nav, setNav] = useState(false)
    const handleClick = () => setNav(!nav)

    return (
        <div className="fixed w-full h-[80px] flex justify-between items-center px-4 bg-[#0a192f] text-gray-300">
            <div>
                <img src={Logo} alt="Logo" className="w-[50px] h-[50px]"/>
            </div>

            {/* menu */}
            
                <ul className='hidden md:flex'>
                    <li>
                    <Link to="home" smooth={true} duration={500}>
                        Home
                    </Link>
                    </li>
                    <li>
                    <Link to="skills" smooth={true} duration={500}>
                        Skills
                    </Link>
                    </li>
                    <li>
                    <Link to="about" smooth={true} duration={500}>
                        About
                    </Link>
                    </li>
                    <li>
                    <Link to="work" smooth={true} duration={500}>
                        Projects
                    </Link>
                    </li>
                    <li>
                    <Link to="contact" smooth={true} duration={500}>
                        Contact
                    </Link>
                    </li>
                </ul>

            {/* Hamburger */}
            <div onClick={handleClick} className=' z-10'>
                {!nav ? <FaBars className='text-2xl md:hidden'/> : <FaTimes className='text-2xl md:hidden'/>}
            </div>

            {/* Mobile Menu */}
            <ul className={!nav ? 'hidden' : 'absolute top-0 left-0 w-full h-screen bg-[#0a192f] flex flex-col justify-center items-center'}>
                   
                    <li className='py-6 text-4xl'>

                    <Link onClick={handleClick} to="home" smooth={true} duration={500}>
                        Home
                    </Link>
                    </li>
                    <li className='py-6 text-4xl'>
                    <Link onClick={handleClick} to="skills" smooth={true} duration={500}>
                        Skills
                    </Link>
                    </li>
                    <li className='py-6 text-4xl'>
                    <Link onClick={handleClick} to="about" smooth={true} duration={500}>
                        About
                    </Link>
                    </li>
                    <li className='py-6 text-4xl'>
                    <Link onClick={handleClick} to="work" smooth={true} duration={500}>
                        Projects
                    </Link>
                    </li>
                    <li className='py-6 text-4xl'>
                    <Link onClick={handleClick} to="contact" smooth={true} duration={500}>
                        Contact
                    </Link>
                    </li>
                </ul>

            {/* Social Icons */}
            <div className='lg:flex fixed flex-col top-[35%] left-0 hover:visible hidden' >
            <ul>
                <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#0072b1]'>
                    <a className='flex justify-between items-center w-full text-gray-300'
                    href="https://www.linkedin.com/in/milos-adnadjevic-11693a7b/">
                        Linkedin <FaLinkedin size={30}/>
                    </a>
                </li>
                <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#333333]'>
                    <a className='flex justify-between items-center w-full text-gray-300'
                    href="https://github.com/milosadnadjevic">
                        GitHub <FaGithub size={30}/>
                    </a>
                </li>
                <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#45d4c6]'>
                    <a className='flex justify-between items-center w-full text-gray-300'
                    href="/">
                        <Link to="contact" smooth={true} duration={500}>Email</Link> <HiOutlineMail size={30}/>
                    </a>
                </li>
                <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#595089]'>
                    <a className='flex justify-between items-center w-full text-gray-300'
                    href="/">
                        <Link to="resume" smooth={true} duration={500}>Resume</Link> <BsFillPersonLinesFill size={30}/>
                    </a>
                </li>
            </ul>
            </div>
        
        </div>
    )
}

export default Navbar