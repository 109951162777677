import React from 'react'
import HTML from '../assets/html.png'
import CSS from '../assets/css.png'
import JS from '../assets/js.png'
import REACT from '../assets/react.png'
import TAILWIND from '../assets/tailwind.png'
import GITHUB from '../assets/github.png'
import AWS from '../assets/aws.png'
import MONGO from '../assets/mongo.png'
import DJANGO from '../assets/django.png'
import PYTHON from '../assets/python.png'
import NODEJS from '../assets/nodejs.png'
import POSTGRESQL from '../assets/postgresql.png'

const Skills = () => {
  return (
    <div name='skills' className='w-full h-screen bg-[#0a192f] text-gray-300'>
        {/* Container */}
        <div>
            <div className='max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full'>
                <div>
                    <p className='text-4xl font-bold inline border-b-4 border-pink-600'>Skills</p>
                    <p className='py-6'>These are the technologies I've worked with</p>
                </div>
                
            </div>
            <div className='w-full grid grid-cols-3 sm:grid-cols-4 gap-4 text-center py-8 px-14'>
                <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
                    <img className='w-20 mx-auto' src={HTML} alt="HTML icon" />
                    <p className='my-4'>HTML</p>
                </div>
                <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
                    <img className='w-20 mx-auto' src={CSS} alt="HTML icon" />
                    <p className='my-4'>CSS</p>
                </div><div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
                    <img className='w-20 mx-auto' src={REACT} alt="HTML icon" />
                    <p className='my-4'>REACT</p>
                </div><div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
                    <img className='w-20 mx-auto' src={JS} alt="HTML icon" />
                    <p className='my-4'>JAVASCRIPT</p>
                </div><div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
                    <img className='w-20 mx-auto' src={MONGO} alt="HTML icon" />
                    <p className='my-4'>MONGODB</p>
                </div><div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
                    <img className='w-20 mx-auto' src={TAILWIND} alt="HTML icon" />
                    <p className='my-4'>TAILWIND</p>
                </div><div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
                    <img className='w-20 mx-auto' src={GITHUB} alt="HTML icon" />
                    <p className='my-4'>GITHUB</p>
                </div><div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
                    <img className='w-20 mx-auto' src={AWS} alt="HTML icon" />
                    <p className='my-4'>AWS</p>
                </div><div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
                    <img className='w-20 mx-auto' src={PYTHON} alt="HTML icon" />
                    <p className='my-4'>PYTHON</p>
                </div><div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
                    <img className='w-20 mx-auto' src={DJANGO} alt="HTML icon" />
                    <p className='my-4'>DJANGO</p>
                </div><div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
                    <img className='w-20 mx-auto' src={NODEJS} alt="HTML icon" />
                    <p className='my-4'>NODE.JS</p>
                </div><div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
                    <img className='w-20 mx-auto' src={POSTGRESQL} alt="HTML icon" />
                    <p className='my-4'>POSTGRESQL</p>
                </div>
            </div>
        </div>

    </div>
  )
}

export default Skills